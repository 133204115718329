import { Component } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AtlasDataService, AtlasLanguageService } from '../../../core/src';
import { ModalService } from '../core-components/modal/modal.service';
import { AtlasLanguageSelectorModalComponent } from '../language-selector/modal/language-selector-modal.component';

const USER_LANGUAGE_MODAL: { [key: string]: string } = {
  PROMPT_WITH_BROWSER_LANG:
    'We noticed your browser language is set to <strong>{{browserLang}}</strong>, but your user profile language is set to <strong>{{userLang}}</strong>.',
  PROMPT_WITHOUT_BROWSER_LANG: 'Your user profile language is currently set to <strong>{{userLang}}</strong>.',
  WANT_TO_SWITCH: "Would you like to confirm your language preferences? We won't ask again for this device.",
  USE_BROWSER_LANGUAGE: 'Switch to {{browserLang}}',
  CONFIRM_USER_LANGUAGE: 'Use {{userLang}}',
  SEE_ALL_LANGUAGE_OPTIONS: 'Select another language',
};

@Component({
  selector: 'atlas-user-language-modal',
  templateUrl: './user-language-modal.component.html',
  styleUrls: ['./user-language-modal.component.scss'],
})
export class AtlasUserLanguageModalComponent {
  public browserLanguage: string;
  public userLanguage$: Observable<string>;
  public switching = false;
  public modalContent$: Observable<{
    userLanguageCode: string;
    promptText: string;
    browserButtonText: string;
    userButtonText: string;
    seeOptionsButtonText: string;
  }>;

  constructor(
    private dataService: AtlasDataService,
    private translateService: TranslateService,
    private translateLoader: TranslateLoader,
    public languageService: AtlasLanguageService,
    private modalService: ModalService,
  ) {
    this.browserLanguage = this.languageService.getLanguage();
    this.userLanguage$ = this.dataService.language$;
    this.modalContent$ = combineLatest([
      of(this.browserLanguage),
      this.userLanguage$,
      this.translateLoader.getTranslation(this.browserLanguage),
      this.translateService.stream('LANGUAGE'),
    ]).pipe(
      map(
        ([browserLanguageCode, userLanguageCode, modalTranslations, languageTranslations]: [
          string,
          string,
          any,
          any,
        ]) => {
          let languageNames, browserLanguage, userLanguage;
          if ('LANGUAGE' in modalTranslations) {
            languageNames = modalTranslations['LANGUAGE'];
          } else {
            languageNames = languageTranslations;
          }

          if (browserLanguageCode in languageNames) {
            browserLanguage = languageNames[browserLanguageCode];
          }
          if (userLanguageCode in languageNames) {
            userLanguage = languageNames[userLanguageCode];
          }
          if ('USER_LANGUAGE_MODAL' in modalTranslations) {
            modalTranslations = modalTranslations.USER_LANGUAGE_MODAL;
          } else {
            modalTranslations = USER_LANGUAGE_MODAL;
          }

          let prompt: string;
          if (browserLanguageCode) {
            prompt = modalTranslations.PROMPT_WITH_BROWSER_LANG.replace('{{userLang}}', userLanguage).replace(
              '{{browserLang}}',
              browserLanguage || browserLanguageCode,
            );
          } else {
            prompt = modalTranslations.PROMPT_WITHOUT_BROWSER_LANG.replace(
              '{{userLang}}',
              userLanguage || userLanguageCode,
            );
          }

          return {
            userLanguageCode: userLanguageCode,
            promptText: `${prompt} ${modalTranslations.WANT_TO_SWITCH}`,
            browserButtonText: (modalTranslations.USE_BROWSER_LANGUAGE as string).replace(
              '{{browserLang}}',
              browserLanguage || browserLanguageCode,
            ),
            userButtonText: (modalTranslations.CONFIRM_USER_LANGUAGE as string).replace(
              '{{userLang}}',
              userLanguage || userLanguageCode,
            ),
            seeOptionsButtonText: modalTranslations.SEE_ALL_LANGUAGE_OPTIONS as string,
          };
        },
      ),
    );
  }

  public setBrowserLang(): void {
    this.switchLanguage(this.browserLanguage, true);
  }

  public confirmUserLang(language: string): void {
    this.switchLanguage(language, false);
  }

  public switchLanguage(language: string, updateConfig?: boolean): void {
    this.switching = true;
    this.languageService.setLanguage(language, updateConfig);
  }

  public openLanguageSelector(): void {
    this.modalService.openInformational(AtlasLanguageSelectorModalComponent);
  }
}
