import { Injectable } from '@angular/core';
import { combineLatest, take } from 'rxjs';
import { AtlasDataService, AtlasLanguageService } from '../../../core/src';
import { ModalService } from '../core-components/modal/modal.service';
import { AtlasUserLanguageModalComponent } from './user-language-modal.component';

@Injectable()
export class AtlasUserLanguageModalService {
  constructor(
    private dataService: AtlasDataService,
    private languageService: AtlasLanguageService,
    private modalService: ModalService,
  ) {}

  public init(): void {
    combineLatest([this.dataService.language$, this.dataService.impersonateeUsername$])
      .pipe(take(1))
      .subscribe(([userLanguage, impersonateeUsername]) => {
        const browserLanguage = this.languageService.getLanguage();
        if (
          !impersonateeUsername &&
          (!browserLanguage || browserLanguage.toLowerCase() !== userLanguage.toLowerCase())
        ) {
          this.modalService.openInformational(AtlasUserLanguageModalComponent);
        }
      });
  }
}
