import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIKitModule } from '@vendasta/uikit';

import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { CommonModule as VLCCommonModule } from './common';
import { RoutingModule } from './routing.module';

import { MatButtonModule } from '@angular/material/button';
import { DEFAULT_LANGUAGE, LexiconModule } from '@galaxy/lexicon';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyI18NModule, getLocale } from '@vendasta/galaxy/i18n';
import { GalaxyDefaultProviderOverrides } from '@vendasta/galaxy/provider-default-overrides';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import baseTranslations from '../assets/i18n/en_devel.json';
import { WEBLATE_COMPONENT_NAME } from './common/constants';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RoutingModule,
    UIKitModule,
    CommonModule,
    BrowserModule,
    VLCCommonModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    MatButtonModule,
    TranslateModule,
    GalaxyI18NModule,
    LexiconModule.forRoot({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslations,
    }),
  ],
  providers: [
    ...GalaxyDefaultProviderOverrides,
    {
      provide: DEFAULT_LANGUAGE,
      useFactory: () => getLocale(),
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfDbqghAAAAAEjSBFRh_4TBj836XmCDEHgymWyl',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
