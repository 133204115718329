import { Component, EventEmitter, output, Output } from '@angular/core';
import { ModalService } from '../../core-components/modal/modal.service';
import { AtlasMenuService } from '../../menu/menu.service';
import { SettingsComponent } from '../settings/settings.component';

@Component({
  selector: 'atlas-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss'],
})
export class FlyoutComponent {
  @Output() markAllAsFollowed: EventEmitter<void> = new EventEmitter();
  closed = output();

  constructor(
    private menuService: AtlasMenuService,
    private modalService: ModalService,
  ) {}

  close(): void {
    this.closed.emit();
    this.menuService.closeMenu('');
  }

  markAllAsFollowedEvent(): void {
    this.markAllAsFollowed.emit();
  }

  openSettings(): void {
    this.modalService.openList(SettingsComponent);
  }
}
