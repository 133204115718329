import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // TODO: what should we do for an unknown route?
  {
    path: '',
    redirectTo: 'account-selector',
    pathMatch: 'full',
  },
  {
    path: 'verify',
    loadChildren: () => import('./email-verification/email-verification.module').then((m) => m.EmailVerificationModule),
  },
  {
    path: 'account-selector',
    loadChildren: () => import('./account-selector/account-selector.module').then((m) => m.AccountSelectorModule),
  },
  {
    path: ':partnerId/account-selector',
    loadChildren: () => import('./account-selector/account-selector.module').then((m) => m.AccountSelectorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class RoutingModule {}
