import { loadTranslations as _loadTranslations } from '@angular/localize';
import { LEXICON_FILE_FORMAT_OPTION } from '@galaxy/lexicon';
import { Language } from './language';

const LOCALE_LOCALSTORAGE_KEY = 'e5190deccf484eea9d9676c8fd75dbc0';
const LANGUAGE_OPTIONS = [
  Language.AFRIKAANS,
  Language.CZECH,
  Language.DUTCH,
  Language.ENGLISH,
  Language.FRENCH,
  Language.FRENCH_CANADA,
  Language.GERMAN,
  Language.HEBREW,
  Language.ITALIAN,
  Language.PORTUGUESE,
  Language.RUSSIAN,
  Language.SPANISH_LATIN_AMERICA,
];

declare global {
  interface Window {
    partnerId: string;
  }
}

// public function for boostrapping internationalization for the application
// weblateComponentName format example: 'advertising-intelligence/digital-ads-client'
export function bootstrapI18n(
  weblateComponentName: string,
  baseTranslations?: any,
  overrideLanguageOptions?: Language[],
  format?: LEXICON_FILE_FORMAT_OPTION,
  forceOtw?: boolean,
  environment?: string,
): void {
  loadTranslationFile(
    getLocale(overrideLanguageOptions),
    weblateComponentName,
    baseTranslations,
    format,
    forceOtw,
    environment,
  );
}

// todo: I don't think I like this overrideLanguageOptions dealio, refactor
export function getLocale(overrideLanguageOptions?: Language[]): string {
  const language =
    getLanguageFromLocalStorage(overrideLanguageOptions) || detectLanguageFromBrowser() || Language.ENGLISH;
  let locale = language.toString();
  // safeguard/default
  if (!locale) {
    locale = 'en';
  }
  return locale;
}

// public function for setting the current language in local storage
export function setLanguage(language: Language | string): void {
  if (localStorage) {
    localStorage.setItem(LOCALE_LOCALSTORAGE_KEY, language);
  }
}

export function getLanguageFromLocalStorage(overrideLanguageOptions?: Language[]): Language | undefined {
  if (localStorage) {
    const languageCode = localStorage.getItem(LOCALE_LOCALSTORAGE_KEY);
    if (languageCode) {
      return findLanguageFromCode(languageCode.toLowerCase(), overrideLanguageOptions);
    }
  }
  return undefined;
}

export function detectLanguageFromBrowser(): Language | undefined {
  const browserLanguageCode = getBrowserCultureLang().toLowerCase();
  return findLanguageFromCode(browserLanguageCode);
}

export function getBrowserCultureLang(): string {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return '';
  }
  // Select language from various browser sources
  let browserCultureLang = Intl.NumberFormat().resolvedOptions().locale;
  if (!browserCultureLang && window.navigator.languages && window.navigator.languages.length) {
    browserCultureLang = window.navigator.languages[0];
  }
  browserCultureLang =
    browserCultureLang ||
    window.navigator.language ||
    (window.navigator as any).browserLanguage ||
    (window.navigator as any).userLanguage;
  return browserCultureLang.replace(/_/g, '-');
}

export function findLanguageFromCode(languageCode: string, overrideLanguageOptions?: Language[]): Language | undefined {
  const languageOptions = overrideLanguageOptions ? overrideLanguageOptions : LANGUAGE_OPTIONS;
  // Search for full string
  let language = languageOptions.find((opt) => {
    return opt.toString().startsWith(languageCode);
  });
  // Search by partial language code
  if (!language && languageCode.indexOf('-') === 2) {
    language = languageOptions.find((opt) => {
      return opt.toString().startsWith(languageCode.slice(0, 2));
    });
  }
  return language;
}

export function buildFilePath(
  locale: string,
  weblateComponentName: string,
  format?: LEXICON_FILE_FORMAT_OPTION,
  environment = 'prod',
): string {
  const env = environment === 'prod' ? 'prod' : 'demo';
  const I18N_ASSETS_BASE_URL = `https://lexicon-${env}.apigateway.co/get-translations`;

  let filepath = I18N_ASSETS_BASE_URL + `?componentNames=${weblateComponentName}&languageCode=${locale}`;
  switch (format) {
    case LEXICON_FILE_FORMAT_OPTION.XLIFF1:
      filepath += `&format=xlf`;
      break;
    default:
      filepath += `&format=json`;
      break;
  }
  if (typeof window !== 'undefined' && window.partnerId) {
    filepath += `&partnerId=${window.partnerId}`;
  }
  return filepath;
}

export function loadTranslationFile(
  locale: string,
  weblateComponentName: string,
  baseTranslations?: any,
  format?: LEXICON_FILE_FORMAT_OPTION,
  forceOtw?: boolean,
  environment?: string,
): void {
  if (!('deployment' in window) && !forceOtw && baseTranslations) {
    loadTranslations(locale, flattenObject(baseTranslations));
    return;
  }

  const filepath = buildFilePath(locale, weblateComponentName, format, environment);
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    if (xhr.status === 200) {
      let translations: Record<string, string>;
      switch (format) {
        case LEXICON_FILE_FORMAT_OPTION.XLIFF1:
          translations = parseXliff(xhr.responseText);
          break;
        case LEXICON_FILE_FORMAT_OPTION.NESTED_JSON:
          translations = parseNestedJSON(xhr.responseText);
          break;
        case LEXICON_FILE_FORMAT_OPTION.FLAT_JSON:
          translations = parseFlatJSON(xhr.responseText);
          break;
        default:
          translations = parseNestedJSON(xhr.responseText);
          break;
      }
      if (Object.keys(translations).length !== 0) {
        loadTranslations(locale, translations);
      }
    }
  };
  const async = false;
  xhr.open('GET', filepath, async);
  xhr.send();
}

export function loadTranslations(locale: string, translations: Record<string, string>): void {
  if (translations && Object.keys(translations).length) {
    _loadTranslations(translations);
  }
  if (locale) {
    $localize.locale = locale;
  }
}

export function parseXliff(content: string): Record<string, string> {
  const translations: Record<string, any> = {};
  const document = new DOMParser().parseFromString(content, 'text/xml');
  const textUnits = Array.from(document.getElementsByTagName('trans-unit'));
  textUnits.forEach((unit) => {
    const target = unit.getElementsByTagName('target').item(0);
    if (target) {
      translations[unit.id] = Array.from(target.childNodes)
        .map((child) => {
          if (child instanceof Text) {
            return child.wholeText;
          } else if (child instanceof Element) {
            return `{$${child.id}}`;
          }
          return '';
        })
        .join('');
    }
  });
  return translations;
}

export function parseNestedJSON(content: string): Record<string, string> {
  const parsedTranslationBundle = JSON.parse(content);
  return flattenObject(parsedTranslationBundle);
}

export function parseFlatJSON(content: string): Record<string, string> {
  return JSON.parse(content);
}

export function flattenObject(ob: Record<string, any>): any {
  const toReturn: Record<string, any> = {};
  let flatObject: Record<string, any>;
  for (const i in ob) {
    if (!(i in ob)) {
      continue;
    }
    if (typeof ob[i] === 'object') {
      flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!(x in flatObject)) {
          continue;
        }
        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export function languageDirectionality(language: string): 'rtl' | 'ltr' {
  switch (language) {
    case Language.HEBREW:
      return 'rtl';
    default:
      return 'ltr';
  }
}
