<atlas-modal>
  <ng-container *ngIf="modalContent$ | async as modalContent; else loading">
    <atlas-modal-content>
      <p style="padding: 24px" [innerHTML]="modalContent.promptText"></p>
    </atlas-modal-content>
    <atlas-modal-footer>
      <atlas-button (onClick)="setBrowserLang()" *ngIf="browserLanguage">
        {{ modalContent.browserButtonText }}
      </atlas-button>
      <atlas-button (onClick)="confirmUserLang(modalContent.userLanguageCode)">
        {{ modalContent.userButtonText }}
      </atlas-button>
      <atlas-button (onClick)="openLanguageSelector()">
        {{ modalContent.seeOptionsButtonText }}
      </atlas-button>
    </atlas-modal-footer>
  </ng-container>
  <ng-template #loading>
    <atlas-loader [size]="50"></atlas-loader>
  </ng-template>
</atlas-modal>
